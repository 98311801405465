<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @handleAccept="handleUpdate"
    @onHiddenSidebar="handleCancel"
    showFooter
  >
    <slot></slot>
    <RequestForm
      id="updateRequestForm"
      ref="updateRequestForm"
      :title="$i18n.t('lb_update_request')"
      @handleOk="handleUpdate"
      @handleCancel="handleCancel"
      :request="getRequest"
      :isLoading="isLoading"
      :validationState="validationState"
      :isDisabled="getRequestStatus"
      :isCreate="false"
      isEdit
    />
  </SideBar>
</template>

<script>
import { uuid } from 'vue-uuid';
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
// import RequestForm from '@/components/organisms/Request/RequestForm.vue';
import { getValidationState, isValidForm } from '@/helpers/ValidationHelper';
import { STATUS } from '@/helpers/ComponentHelper';
// import DoctorFactory from '@/domain/doctor/DoctorFactory';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    request: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      validationState: {
        patient: null,
        specialty: null,
        level: null,
        pathology: null,
        medicalProcedure: null,
        clinic: null,
      },
      isDisabled: false,
    };
  },
  methods: {
    handleUpdate() {
      const data = this.$refs.updateRequestForm.getData();

      const patientValidationState = getValidationState(
        data.patient?.id,
      );

      const specialtyValidationState = getValidationState(
        data.specialty?.id,
      );

      const levelValidationState = getValidationState(
        data.level,
      );

      const pathologyValidationState = getValidationState(
        data.pathology,
      );

      const clinicValidationState = getValidationState(
        data.clinic?.id,
      );

      const medicalProcedureValidationState = getValidationState(
        data.medicalProcedure,
      );

      if (isValidForm([
        patientValidationState,
        specialtyValidationState,
        levelValidationState,
        pathologyValidationState,
        clinicValidationState,
        medicalProcedureValidationState,
      ])) {
        this.update(data);
      } else {
        this.validationState.patient = patientValidationState;
        this.validationState.specialty = specialtyValidationState;
        this.validationState.level = levelValidationState;
        this.validationState.pathology = pathologyValidationState;
        this.validationState.clinic = clinicValidationState;
        this.validationState.medicalProcedure = medicalProcedureValidationState;
      }
    },

    getDocumentsForRequest(documents) {
      const newDocuments = documents.map((document) => {
        const newDocument = {
          id: uuid.v4(),
          documentId: document.id,
        };

        return newDocument;
      });

      return newDocuments;
    },

    update(data) {
      const requestData = {
        id: data.id,
        body: {
          patient: data.patient?.id,
          specialty: data.specialty?.id,
          level: data.level,
          pathology: data.pathology,
          medicalProcedure: data.medicalProcedure,
          hasInsurance: data.hasInsurance,
          hasRecentReports: data.hasRecentReports,
          healthManager: data.healthManager?.id,
          clinic: data.clinic?.id,
          documents: this.getDocumentsForRequest(data.documents),
          stage: data.requestStage,
          doctor: this.getDoctorID(data.doctor?.id),
          doctors: data.doctors || [],
        },
      };

      this.isLoading = true;

      this.$store.dispatch('request/updateRequest', requestData)
        .then(() => {
          requestData.specialty = data.specialty;
          this.$emit('updateRequest', data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    handleCancel() {
      this.$emit('handleCancel');
      this.resetValidations();
    },

    resetValidations() {
      this.validationState.patient = null;
      this.validationState.specialty = null;
      this.validationState.level = null;
      this.validationState.pathology = null;
      this.validationState.medicalProcedure = null;
      this.validationState.clinic = null;
    },

    getDoctorID(data) {
      if (this.isDoctor && data === undefined) {
        return this.getUser.id;
      }

      return data;
    },
  },
  computed: {
    getRequest() {
      return this.request;
    },

    isHealthManager() {
      return this.$store.getters['user/isHealthManager'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    getRequestStatus() {
      return this.request.status === STATUS.ACCEPTED.key
        && (this.isHealthManager || this.isClinic || this.isDoctor);
    },

    getUser() {
      return this.$store.getters['user/getCurrentUser'];
    },
  },
  mounted() {},
  components: {
    SideBar,
    RequestForm: () => import('@/components/organisms/Request/RequestForm.vue'),
  },
};
</script>
